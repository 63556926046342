import { createSelector } from '@ngrx/store';
import { selectPostFeature } from '../post/post.selectors';

export const selectDifferentialPosts = createSelector(
    selectPostFeature,
    (state) => state.diffPosts,
);

export const selectAreThereDiffPosts = createSelector(
    selectPostFeature,
    (state) => state.diffPosts.length > 0,
);

export const selectDifferentialPostDetail = createSelector(
    selectPostFeature,
    (state) => state.diffPostDetail,
);
